var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[_c('Header',{attrs:{"hasSearch":false,"hasShadow":true}}),_c('div',{staticClass:"container mt-80 mb-20 pb-20"},[_c('el-card',{staticClass:"mt-20",attrs:{"shadow":"hover","body-style":{ padding: '20px' }}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('h5',[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('el-input',{attrs:{"placeholder":"关键词搜索"},model:{value:(_vm.params.keyword),callback:function ($$v) {_vm.$set(_vm.params, "keyword", $$v)},expression:"params.keyword"}})],1)]),_vm._l((_vm.lists),function(item){return _c('router-link',{key:'item' + item.id,staticClass:"block flex-fill",attrs:{"to":{ path: '/race/detail/' + item.id },"target":"_blank"}},[(item.thumb)?_c('img',{staticClass:"thumb",attrs:{"src":_vm.viewThumb(item.thumbData.thumb, 'medium')}}):_vm._e(),_c('div',{staticClass:"block-body"},[_c('div',{staticClass:"block-body-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"block-body-extend"},[_c('div',[_vm._v(" "+_vm._s(item.startTime)+"至"+_vm._s(item.endTime)+" "),_c('span',{staticClass:"text-info"},[_vm._v(_vm._s(_vm.raceStatus(item)))])]),_c('div',[_vm._v(" "+_vm._s(item.video)+" "),_c('span',{staticClass:"text-gray-6 mr-20"},[_vm._v("参赛")]),(
                  item.is_close < 1 &&
                  _vm.$utils.time() > item.start_time &&
                  _vm.$utils.time() < item.end_time
                )?[_c('router-link',{attrs:{"to":{
                    path: '/ucenter/publish',
                    query: {
                      race_id: item.id,
                    },
                  },"target":"_blank"}},[_vm._v("我要投稿")])]:_vm._e()],2)]),_c('div',{staticClass:"block-body-desc"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"block-body-desc"},[_c('span',[_vm._v(" "+_vm._s(item.createDate)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$utils.contrastTime(item.create_time)))])])])])}),(_vm.params.pages > 1)?_c('div',{staticClass:"text-center mt-20"},[_c('el-pagination',{attrs:{"current-page":_vm.params.page,"page-size":_vm.params.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.params.total},on:{"size-change":_vm.pageSizeChange,"current-change":_vm.pageChange}})],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }